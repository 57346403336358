import validate from "/codebuild/output/src2843693253/src/seb-brand-platform/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/codebuild/output/src2843693253/src/seb-brand-platform/apps/wmf/middleware/redirect.global.ts";
import manifest_45route_45rule from "/codebuild/output/src2843693253/src/seb-brand-platform/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "cors-preflight-request": () => import("/codebuild/output/src2843693253/src/seb-brand-platform/apps/wmf/middleware/cors-preflight-request.ts")
}